// Generated by Framer (3f65431)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { onAppear } from "https://framerusercontent.com/modules/ztHeKdDYIwNs62qRUiAl/lkdNywPv8XWYaCZyqsXP/Scrambler.js";
const RichTextOnAppear = onAppear(RichText);

const serializationHash = "framer-Tiqnk"

const variantClassNames = {SYBjuog7p: "framer-v-1q8hnll"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, jNLzExfHL: click ?? props.jNLzExfHL} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jNLzExfHL, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "SYBjuog7p", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapj4kd04 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (jNLzExfHL) {const res = await jNLzExfHL(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://maps.app.goo.gl/NS983YZh4UJBEeyx7"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1q8hnll", className, classNames)} framer-545gqt`} data-framer-name={"primary"} data-highlight layoutDependency={layoutDependency} layoutId={"SYBjuog7p"} onTap={onTapj4kd04} ref={ref ?? ref1} style={{backgroundColor: "var(--token-766bf9c4-f0b5-4844-b7a4-c10a978ad258, rgb(235, 51, 1))", ...style}}><RichTextOnAppear __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dUIFByZXNzdXJhIFRyaWFsIEx0", "--framer-font-family": "\"GT Pressura Trial Lt\", \"GT Pressura Trial Lt Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-letter-spacing": "0.2em", "--framer-line-height": "1.6em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-b1dc2fec-7be9-4139-9683-cb1397d923a7, rgb(255, 255, 255)))"}}>SEE VENUE</motion.p></React.Fragment>} className={"framer-1soyg1x"} fonts={["CUSTOM;GT Pressura Trial Lt"]} layoutDependency={layoutDependency} layoutId={"cH4HLxmE8"} style={{"--extracted-r6o4lv": "var(--token-b1dc2fec-7be9-4139-9683-cb1397d923a7, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Tiqnk.framer-545gqt, .framer-Tiqnk .framer-545gqt { display: block; }", ".framer-Tiqnk.framer-1q8hnll { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 15px 30px 15px 30px; position: relative; text-decoration: none; width: min-content; }", ".framer-Tiqnk .framer-1soyg1x { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Tiqnk.framer-1q8hnll { gap: 0px; } .framer-Tiqnk.framer-1q8hnll > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Tiqnk.framer-1q8hnll > :first-child { margin-left: 0px; } .framer-Tiqnk.framer-1q8hnll > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52.5
 * @framerIntrinsicWidth 148
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"jNLzExfHL":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervkinJvo3M: React.ComponentType<Props> = withCSS(Component, css, "framer-Tiqnk") as typeof Component;
export default FramervkinJvo3M;

FramervkinJvo3M.displayName = "Button 3";

FramervkinJvo3M.defaultProps = {height: 52.5, width: 148};

addPropertyControls(FramervkinJvo3M, {jNLzExfHL: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramervkinJvo3M, [{explicitInter: true, fonts: [{family: "GT Pressura Trial Lt", source: "custom", url: "https://framerusercontent.com/assets/PAG3pOUxhA1VaKdovU992bEVxA.woff2"}]}], {supportsExplicitInterCodegen: true})